import React from "react"
import { graphql } from "gatsby"

import "../styles/post.scss"

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <main className="container-fluid px-4">
                <nav className="navbar fixed-top navbar-light bg-light">
                  <a className="navbar-brand" href="/">Straightup.wtf</a>
                  <span className="navbar-text">
                      <h2>{frontmatter.title}</h2>
                  </span>
                </nav>

                <div
                    dangerouslySetInnerHTML={{ __html: html }}
                />

        </main>

    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`